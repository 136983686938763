import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

const BaseButton = ({
  onClick,
  className,
  children,
}: PropsWithChildren<{
  onClick: () => void;
  className?: string;
}>) => {
  return (
    <div
      onClick={onClick}
      className={`${className} text-title-20m cursor-pointer select-none rounded-full px-8 py-3 text-white`}
    >
      {children}
    </div>
  );
};

export default BaseButton;

export interface IButtonProps {
  className?: string;
  onClick: () => void;
}

const PrimaryButton = ({
  onClick,
  className,
  children,
}: PropsWithChildren<IButtonProps>) => {
  return (
    <BaseButton
      onClick={onClick}
      className={clsx(
        className,
        'bg-bk-100 hover:bg-pri-100 active:bg-[#A64600]'
      )}
    >
      {children}
    </BaseButton>
  );
};

const SecondaryButton = ({
  onClick,
  className,
  children,
}: PropsWithChildren<IButtonProps>) => {
  return (
    <BaseButton
      onClick={onClick}
      className={clsx(
        className,
        'bg-sec-100 hover:bg-sec-100 active:bg-[#3A45A8]'
      )}
    >
      {children}
    </BaseButton>
  );
};

export { PrimaryButton, SecondaryButton };
