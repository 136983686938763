import React, { PropsWithChildren, useState } from 'react';
import { TfiAlignJustify as NavIcon } from 'react-icons/tfi';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { SecondaryButton } from '@/components/button';
import Sidebar from '@/components/sidebar';

import { navList } from './config';

const NavButton = ({
  href,
  currentPath,
  className,
  children,
}: PropsWithChildren<{
  href: string;
  currentPath: string;
  className?: string;
}>) => {
  const isActive = currentPath === href;

  return (
    <a
      href={href}
      className={clsx(
        className,
        'cursor-pointer select-none text-title-16r',
        isActive ? 'text-pri-100' : 'text-bk-100'
      )}
    >
      {children}
    </a>
  );
};

const Header = () => {
  const { pathname } = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleContactClick = () => {};

  const handleSidebarShow = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarHide = () => {
    setIsSidebarOpen(false);
  };

  const getNavButtons = () => {
    return navList.map((nav) => (
      <NavButton key={nav.name} href={nav.href} currentPath={pathname}>
        {nav.name}
      </NavButton>
    ));
  };

  return (
    <div className='border-b border-border-base '>
      <div className='container flex flex-row items-center justify-between py-4'>
        <div className='flex flex-row items-center gap-x-12'>
          {/* logo */}
          <div className='relative h-[28px] w-[105px] lg:h-[56px] lg:w-[213px]'>
            <Image
              src='/image/header/logo.png'
              alt='logo'
              fill
              //width={213}
              //height={56}
            />
          </div>
          {/* nav */}
          <div
            className='hidden flex-row items-center gap-x-8 md:flex'
            role='navigation'
          >
            {getNavButtons()}
          </div>
        </div>
        <div className='hidden flex-row items-center gap-x-8 lg:flex'>
          <SecondaryButton onClick={handleContactClick}>
            聯絡我們
          </SecondaryButton>
          <div className='text-bk-100'>繁體中文</div>
        </div>
        <div className='flex md:hidden'>
          <button onClick={handleSidebarShow}>
            <NavIcon />
          </button>
          <Sidebar isShow={isSidebarOpen} onClose={handleSidebarHide}>
            {getNavButtons()}
          </Sidebar>
        </div>
      </div>
    </div>
  );
};

export default Header;
