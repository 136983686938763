// react query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import Header from '@/components/header';

import '@/styles/globals.css';

// Create a client
const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='min-h-lvh'>
        <Head>
          <title>獨立出版</title>
        </Head>
        <Header />
        <Component {...pageProps} />
      </div>
    </QueryClientProvider>
  );
}
