import React, { PropsWithChildren } from 'react';
import { RiCloseLargeLine as CloseIcon } from 'react-icons/ri';
import clsx from 'clsx';

interface ISidebarProps {
  isShow: boolean;
  onClose: () => void;
}

const Sidebar = ({
  isShow,
  onClose,
  children,
}: PropsWithChildren<ISidebarProps>) => {
  return (
    <div
      className={clsx(
        'fixed right-0 top-0 z-10 h-full w-64 bg-white bg-opacity-50 p-5 shadow-lg backdrop-blur duration-300',
        isShow ? 'translate-x-0' : 'translate-x-full'
      )}
    >
      <div className='flex w-full items-end justify-end'>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className='flex flex-col gap-2 overflow-y-auto py-5'>{children}</div>
    </div>
  );
};

export default Sidebar;
