const navList = [
  {
    name: '出版服務',
    href: '/',
  },
  {
    name: '出版流程',
    href: '/flow',
  },
  {
    name: '經驗分享',
    href: '/share',
  },
  {
    name: '獨立出版著作',
    href: '/books',
  },
  {
    name: '經銷通路',
    href: '/channel',
  },
];

export { navList };
